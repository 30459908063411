import React, { useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import ReactPlayer from 'react-player'
import { motion, transform, } from 'framer-motion'
import FullHeight from 'react-div-100vh'
import { usePortals } from "react-portal-hook";
import { useInView } from 'react-intersection-observer';

import { VideoModal } from '../../components'

import { padding, colours, type, bgIcon, hoverState, bgImage } from '../../styles/global'
import { useMount, useWindowScroll, useWindowSize, useUnmount } from 'react-use'
import { defaultEase } from '../../utils/anim'
import { toggleScrollLock } from '../../utils'
import { isClient, useBreakpoint, media } from '../../styles/utils'
import { Link } from 'gatsby'

const HomeHero = (props) => {
    const { data } = props;
    const [mounted, setMounted] = useState(false)
    const { y: scrollY } = useWindowScroll()
    const { height } = useWindowSize()
    const [titleAnim, setTitleAnim] = useState(false)
    const [videoAnimate, setVideoAnimate] = useState(false)
    const portalManager = usePortals()
    const isPhone = useBreakpoint('phone')
    const y = mounted ? scrollY : 0 // workaround for useWindowScroll reporting old Y value after page transitions
    const videoFade = transform(y, [0, (height * 0.7)], [1, 0])
    const [BGVisible, setBGVisible] = useState(false)
    const [ref, inView] = useInView({triggerOnce: true});

	useEffect(() => {
		if (inView) {
            console.log('seen: hero')
        }
	}, [inView])
    
    useMount(() => {     
        if (isClient()) {
            if (window.scrollY <= height) {
                toggleScrollLock(true)
    
                window.scrollTo(0, 0)
    
                setTimeout(() => {
                    setTitleAnim('in')
                    setVideoAnimate('in')
                }, 1000)
            } else {

            }
        }

        setTimeout(() => {
            setMounted(true)
            setVideoAnimate('in')
        }, 100);
    })

    useEffect(() => {
		if (y > height && !BGVisible) setBGVisible(true)
		if (y < height && BGVisible) setBGVisible(false)
	}, [y])
    
    useUnmount(() => {
		document.body.style.overflow = 'auto'
    })

    const showVideo = (video) => {
        portalManager.open(modal => (
			<VideoModal
                video={data?.hero_video_modal}
				{...modal}
			/>
        ))
	}
    
    const refresh = () => {
        if (!isClient()) return;
        window.scrollTo(0, 0)
        location.reload()
    }

    const getHeroVideo = () => {
        if (!data) return;
        const { hero_video_preview_mobile, hero_video_preview_desktop } = data 
        
        if (hero_video_preview_mobile && isPhone) {
            return hero_video_preview_mobile
        }        

        return hero_video_preview_desktop
    }
    
	const renderTitleAnim = () => {
        const spacerScale = transform(y, [0, (height * 0.7)], [1, 0])
        const rightFade = transform(y, [0, (height * 0.2)], [1, 0])
        const rightTranslateX = transform(y, [0, (height * 0.2)], [0, -80])
        
		return (
			<HeroTitle
                fixed={y > (height - 140)}
            >
				<Row>
                    <Refresh
                        onClick={refresh}
                    >
                        <Heading>Chris</Heading>
                        <Block/>
                    </Refresh>
                    
					<SpacerBlock
                        {...spacerAnims}
                        animate={titleAnim}
                        style={{
                            scaleX: spacerScale
                        }}
					/>

					<Heading
                        className={'futurist'}
                        style={{
                            opacity: rightFade,
                            x: rightTranslateX,
                            y: isPhone ? -2 : -3
                        }}
					>
						Futurist
					</Heading>

					<Block
                        style={{
                            opacity: rightFade,
                            scaleX: rightFade,
                            x: rightTranslateX,
                        }}
                    />
				</Row>

				<Row>
                    <Refresh
                        onClick={refresh}
                    >
                        <Block/>
                        <Heading>Riddell</Heading>
                    </Refresh>

					<SpacerBlock
                        {...spacerAnims}
                        animate={titleAnim}
                        style={{
                            scaleX: spacerScale
                        }}
					/>

					<Heading
                        className={'optimist'}
                        style={{
                            opacity: rightFade,
                            x: rightTranslateX,
                            y: isPhone ? -2 : -3
                        }}
					>
						Optimist
					</Heading>
				</Row>
		
			</HeroTitle>
		)
    }
    		
	return (
		<>
            <Hero>
                <AnimatedVideo
                    ref={ref}
                    {...videoAnim}
                    animate={videoAnimate}
                    onAnimationComplete={() => {
                        setTimeout(() => {
                            toggleScrollLock(false)
                        }, 500)
                    }}
                >
                    <FadeVideo
                        style={{
                            opacity: videoFade
                        }}
                    >
                        <Video
                            url={getHeroVideo()}
                            playsinline
                            playing
                            autoplay
                            muted
                            loop
                            width={'100%'}
                            height={'100%'}
                            config={{
                                file: {
                                    attributes: { 
                                        preload: true
                                    }
                                }
                            }}
                        />

                        <PlayIcon
                            onClick={showVideo}
                        />
                    </FadeVideo>
                </AnimatedVideo>    

                {renderTitleAnim()}    

                {!props.hideLogo && (
                    <BackgroundFade
                        style={{
                            opacity: BGVisible ? 1 : 0
                        }}
                    />
                )}
 
            </Hero>       

            <Scroll/>
        </>
	)
}

// Shared

const Heading = styled(motion.div)``

const Icon = styled.div`
	background-image: url(${props => props.image});  
	${bgIcon};
`

const Video = styled(ReactPlayer)``

// Hero

const PlayIcon = styled(Icon)`
	background-image: url(${require('../../assets/icons/play.svg')});
	width: 50px;
	height: 50px;
    background-size: 32px 32px;
	${hoverState}
`

const Hero = styled(FullHeight)`
    height: 100vh;
    width: 100%;
    position: relative;

	video {
		object-fit: cover;
	}

	${PlayIcon} {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}
`

// Hero Video

const videoAnim = {
	initial: {
        opacity: 0,
    },
    variants: {
        in: {
            opacity: 1,
            transition: {
                delay: 1.5,
                duration: 0.75,
                ease: defaultEase
            }
        },
    }
}

const AnimatedVideo = styled(motion.div)`
    height: 100%;
`

const FadeVideo = styled(motion.div)`
    height: 100%;
`

// Hero Title (Animation)

const Row = styled.div``

const Refresh = styled.div`
    display: flex;
`

const Block = styled(motion.div)`
	height: 0.72em;
	background: white;
`

const SpacerBlock = styled(motion.div)`
	height: 0.72em;
	background: white;
    margin: 0 4px;
    width: 100%;
    transform-origin: left top;
`

const spacerAnims = {
	initial: {
        scaleX: 0,
    },
    variants: {
        in: {
            scaleX: 1,
            transition: {
                duration: 2,
                ease: defaultEase
            }
        },
    }
}

const HeroTitle = styled(motion.div)`
	position: absolute;
	left: 0;
	right: 0;
    bottom: 34px;
    ${padding};

	display: flex;
	flex-direction: column;

    ${media.phone`
        bottom: 15px;
    `}

	${Row} {
		display: flex;

		&:nth-child(1) {
			.futurist {
				margin-left: auto;
            }	

            ${Refresh} {
                ${Block} {
                    min-width: 90px;
                    margin-left: 4px;

                    ${media.phone`
                        min-width: 78px;
                    `}
			    }	
            }
            
            ${SpacerBlock} {
                margin-left: 0;
            }

			> ${Block}:last-child {
				min-width: 30px;
                margin-left: 4px;
                transform-origin: left center;
			}		
		}		

		&:nth-child(2) {
			${Block}:first-child {
				min-width: 47px;
				margin-right: 2px;
			}

			.optimist {
				margin-left: auto;
			}	
		}
	}

	${Heading},
	${Block},
	${SpacerBlock} {
		${type.heading3}
    }
    
    ${Heading} {
        transform: translateY(-3px)
    }

    ${props => {
        if (props.fixed) return css`
            position: fixed;
            top: 30px;
            bottom: auto;
            z-index: 4;

            ${Block} {
                background: ${colours.lemon}
            }

            ${media.phone`
                top: 15px;
                bottom: auto;
            `}
        `
    }}
`

// Scroll Indicator

const fade = keyframes`
    0%   { opacity: 0; }
    40%  { opacity: 1; }
    60%  { opacity: 1; }
    100% { opacity: 0; }
`

const Scroll = styled.div`
    background-image: url(${require('../../assets/images/hero-scroll-hover.png')});
    width: 87px;
    height: 15px;
    ${bgImage}
    
    position: absolute;
    top: 30px;
    left: 30px;
    animation: ${fade} 1.5s linear infinite;

    ${media.phone`
        top: 15px;
        left: 15px;
    `}
`

// Background Fade

const BackgroundFade = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	height: 130px;
	background-image: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.68) 56%, rgba(0,0,0,0.00) 100%);
	z-index: 3;
	pointer-events: none;

	/* Animation */

	opacity: 0;
	transition: opacity 0.45s ease;

    ${media.phone`
        height: 100px;
    `}
`



export default HomeHero