import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { motion } from 'framer-motion'
import { useWindowSize, useMount } from 'react-use'
import { useInView } from 'react-intersection-observer';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import { Layout, MarkLink, Mark, AnimatedText, FeaturedEngagements } from '../../components'
import Hero from './Hero'
import Intro from './Intro'

import { container, padding, colours, type, bgImage, bgIcon, hoverState, wrapper } from '../../styles/global'
import { parseACF } from '../../utils'
import { useScrollAnim, transition } from '../../utils/anim'

import logoCondensed from '../../assets/images/logo-condensed.svg'
import { media, useBreakpoint, breakpoints, isClient } from '../../styles/utils'

const Index = (props) => {
	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)

	return (
		<Layout
			hideLogo={true}
            meta={data.seo}
        >
			<Wrapper>
				<Hero
					data={data}
				/>

				<Intro
					data={data}
				/>

				<Container>
				

					<AboutSection
						data={data}
					/>

					<SpeakingSection
						data={data}
					/>
				</Container>

				<LiveSection
					data={data}
				/>

				<Container>
					<InsightsSection
						data={data}
					/>
				</Container>

				{data.featured_engagments && (
					<FeaturedEngagements 
						slides={data.featured_engagments} 
						heading={data?.featured_engagments_heading}
					/>
				)}
			</Wrapper>
		</Layout>	
	)
}

const AboutSection = ({data}) => {
	const { about_image, about_text, about_link_text } = data;
	const [imageRef, { scaleX }] = useScrollAnim({reverse: true});
	const [linkRef, linkAnim] = useScrollAnim();
		
	return (
		<About>
			{about_image && (
				<Image
					key={about_image?.sizes?.large}
					src={about_image?.sizes?.large}
				>
					{(src, loading) => {
						return (
							<ImageWrapper
								ref={imageRef}
							>
								<ImageBlock
									animate={{
										scaleX,
										originX: '100%',
										transition
									}}  
								/>

								<BGImage 
									image={about_image?.sizes?.large} 
									style={{
										opacity: loading ? 0 : 1,
									}}
								/>
							</ImageWrapper>
						)
					}}
				</Image> 
			)}
			
			<Info>
				<Text
					as={AnimatedText}
					text={about_text}
				/>
				
				<Subheading
					innerRef={linkRef}
					as={MarkLink}
					link={'/about'}
					// style={{
					// 	opacity: linkAnim.opacity,
					// 	x: linkAnim.x,
					// }}  
				>
					{about_link_text}
				</Subheading>
			</Info>
		</About>
	)
}

const SpeakingSection = ({data}) => {
	const { speaking_text, speaking_link_text, speaking_image } = data;
	const [headingRef, { opacity, x }] = useScrollAnim();
	const [linkRef, linkAnim] = useScrollAnim();
	const [ref, inView] = useInView({triggerOnce: true});

	useEffect(() => {
		if (inView) trackScrollProgress('speaking')
	}, [inView])

	const renderImage = (breakpoint) => {
		if (!speaking_image) return;
		const [imageRef, { scaleX }] = useScrollAnim({reverse: true});

		return (
			<Image
				key={speaking_image?.sizes?.medium2}
				src={speaking_image?.sizes?.medium2}
			>
				{(src, loading) => {
					return (
						<ImageWrapper
							className={breakpoint}
							ref={imageRef}
						>
						
							<ImageBlock
								animate={{
									scaleX,
									originX: '100%',
									transition
								}}  
							/>

							<BGImage 
								image={speaking_image?.sizes?.large} 
								style={{
									opacity: loading ? 0 : 1,
								}}
							/>
						</ImageWrapper>
					)
				}}
			</Image> 
		)
	}

	return (
		<Speaking
			ref={ref}
		>
			<Info>
				<Heading
					ref={headingRef}
					animate={{
						opacity,
						x,
						transition
					}}
				>
					<Mark
						size={'small'}
					/>
					Speaking
				</Heading>

				{renderImage('phone')}

				<Text
					as={AnimatedText}
					text={speaking_text}
				/>

				<Subheading
					innerRef={linkRef}
					as={MarkLink}
					link={'/speaking'}
					animate={{
						opacity: linkAnim.opacity,
						x: linkAnim.x,
						transition: linkAnim.transition
					}}  
				>
					{speaking_link_text}
				</Subheading>
			</Info>

			{renderImage('desktop')}
		</Speaking>
	)
}

const LiveSection = ({data}) => {
	if (!data.live_display) return;
	const { live_link_text, live_text, live_video } = data;
	const [headingRef, { opacity, x, transition }] = useScrollAnim();
	const [imageRef, { scaleX }] = useScrollAnim({reverse: true});
	const [isPhone, setIsPhone] = useState(false)
	const { width } = useWindowSize();
	const [ref, inView] = useInView({triggerOnce: true});

	useEffect(() => {
		if (inView) trackScrollProgress('live')
	}, [inView])

	useMount(() => {
		checkWidth()
	})

	useEffect(() => {
		checkWidth()
	}, [width])

	const checkWidth = () => {
		if (width <= breakpoints.phone) setIsPhone(true)
	}
	
	const renderNextEp = (breakpoint) => {
		if (!data.live_next_episode_display) return;

		return (
			<NextEpisode
				className={breakpoint}
				animate={{
					opacity,
					x,
					transition
				}}
			>
				<Subheading>Next Episode</Subheading>
				<Subheading
					dangerouslySetInnerHTML={{__html: data.live_next_episode_text}}
				/>
			</NextEpisode>
		)
	}

	const renderVideo = (breakpoint) => {
		if (breakpoint == 'phone' && !isPhone) return;
		if (breakpoint == 'desktop' && isPhone) return;
		
		return (
			<ImageWrapper
				className={breakpoint}
			>
				<Video
					url={live_video}
					playsinline
					playing
					autoplay
					muted
					loop
					width={'100%'}
					height={'100%'}
					config={{
						file: {
							attributes: { 
								preload: true
							}
						}
					}}
				/>

				{breakpoint == 'phone' && renderNextEp('phone')}
			</ImageWrapper>
		)
	}
	
	return (
		<Live
			ref={ref}
		>
			<Container>
				<Info>
					<Heading
						ref={headingRef}
						animate={{
							opacity,
							x,
							transition
						}}
					>
						<LogoCondensed
							src={logoCondensed}
						/>
						/Live
					</Heading>

					{renderVideo('phone')}

					<Text
						as={AnimatedText}
						text={live_text}
					/>

					<Subheading
						as={MarkLink}
						link={'/live'}
					>
						{live_link_text}
					</Subheading>					
				</Info>

				{renderNextEp('desktop')}
			</Container>

			{renderVideo('desktop')}
		</Live>
	)
}

const InsightsSection = ({data}) => {
	if (!data.insights_display) return
	const { insights_text, insights_link_text, insights_image } = data;
	const [headingRef, { opacity, x }] = useScrollAnim();
	const [linkRef, linkAnim] = useScrollAnim();
	const [ref, inView] = useInView({triggerOnce: true});

	useEffect(() => {
		if (inView) trackScrollProgress('insights')
	}, [inView])

	const renderImage = (breakpoint) => {
		if (!insights_image) return;
		const [imageRef, { scaleX }] = useScrollAnim({reverse: true});

		return (
			<Image
				key={insights_image?.sizes?.large}
				src={insights_image?.sizes?.large}
			>
				{(src, loading) => {
					return (
						<ImageWrapper
							className={breakpoint}
							ref={imageRef}
						>
							<ImageBlock
								animate={{
									scaleX,
									originX: '100%',
								}}  
							/>

							<BGImage 
								image={src} 
								style={{
									opacity: loading ? 0 : 1,
								}}
							/>
						</ImageWrapper>
					)
				}}
			</Image> 
		)
	}
	

	return (
		<Insights
			ref={ref}
		>
			<Info>
				<Heading
					ref={headingRef}
					animate={{
						opacity,
						x,
						transition
					}}
				>
					<LogoCondensed
						src={logoCondensed}
					/>
					/Insights
				</Heading>

				{renderImage('phone')}

				<Text
					as={AnimatedText}
					text={insights_text}
				/>

				<Subheading
					innerRef={linkRef}
					as={MarkLink}
					link={'/insights'}
					style={{
						opacity: linkAnim.opacity,
						x: linkAnim.x,
					}}  
				>
					{insights_link_text}
				</Subheading>
			</Info>

			{renderImage('desktop')}
		</Insights>
	)
}

const trackScrollProgress = (section) => {
	trackCustomEvent({
		category: 'userScrollOnHome',
		label: 'Event for tracking user scroll progress on home page',
		value: section
	})
}


// Shared

const Heading = styled(motion.h2)``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const Icon = styled.div`
	background-image: url(${props => props.image});  
	${bgIcon};
`

const ImageWrapper = styled.div`
	position: relative;

	${media.phone`
		width: 100%;
	`}
`

const ImageBlock = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: black;
	z-index: 2;
`

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Video = styled(ReactPlayer)``

const LogoCondensed = styled.img`
	margin-right: 0.1em;

	${media.tablet`
		height: 36px;
	`}
`

const condensedLogoResponsive = css`
	@media (max-width: 1350px) {
		${Heading} {
			font-size: 90px;
		}

		${LogoCondensed} {
			height: 65px;
		}
	}	

	@media (max-width: 1030px) {
		${Heading} {
			font-size: 70px;
		}

		${LogoCondensed} {
			height: 45px;
		}
	}	

	@media (max-width: 850px) {
		${Heading} {
			font-size: 40px;
		}

		${LogoCondensed} {
			height: 29px;
		}
	}	
`

// Layout

const Wrapper = styled.div`
	${wrapper};
	
	${media.phone`
		max-width: 100vw;
	
	`}

	.desktop {
		${media.phone`
			display: none !important;
		`}
	}

	.phone {
		display: none;

		${media.phone`
			display: flex;
		`}
	}
`

const Container = styled.div`
	${container}
	${padding}

	display: flex;
	flex-direction: column;
`

const section = css`
	display: flex;
	justify-content: center;
	margin-top: 240px;
	width: 100%;

	${media.tablet`
		margin-top: 120px;
    `}

	${media.phone`
		flex-direction: column;
		margin-top: 115px;
	`}
`

// About

const About = styled.div`
	${section}
	
	${ImageWrapper} {
		margin-right: 25px;

		${BGImage} {
			width: 255px;
			height: 340px;

			${media.phone`
				width: 134px;
				height: 180px;
			`}
		}
	}

	${Info} {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		margin-right: 107px;
		margin-left: auto;

		${Text} {
			${type.heading3};
			max-width: 810px;
		}

		${Subheading} {
			margin-top: auto;
		}
		
		${media.tablet`
			margin-right: 24px;
		`}

		${media.phone`
			margin-right: 0;
			margin-top: 23px;
		`}
	}

`

// Speaking

const Speaking = styled.div`
	${section}

	${Info} {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-right: auto;

		${Heading} {
			display: flex;
			align-items: center;
			overflow: hidden;
			${type.heading1}

			${media.phone`
				margin-bottom: 26px;
			`}
		}

		${Text} {
			margin-top: 50px;
			${type.heading3};
			max-width: 745px;

			${media.phone`
				margin-top: 0;
			`}
		}

		${Subheading} {
			margin-top: auto;
		}

		${condensedLogoResponsive}
	}
		
	${ImageWrapper} {
		margin-left: 24px;
		
		${BGImage} {
			width: 440px;
			height: 593px;

			${media.phone`
				width: 100%;
				height: 464px;
			`}
		}

		${media.phone`
			margin-left: 0;
			margin-bottom: 22px;
		`}
	}
`

// Live

const NextEpisode = styled(motion.div)``

const Live = styled.div`
	${section}
	height: 810px;
	position: relative;
	padding: 68px 0 57px;
	box-sizing: border-box;

	${media.tablet`
		height: 600px;
	`}

	${media.phone`
		height: auto;
		padding: 0;
	`}

	${Container} {
		position: relative;

		${Info} {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-right: auto;
			z-index: 2;
			position: relative;
			height: 100%;

			${Heading} {
				display: flex;
				align-items: center;
				overflow: hidden;
				${type.heading1}
			}

			${Text} {
				margin-top: 50px;
				${type.heading3};
				max-width: 495px;

				${media.phone`
					max-width: 100%;
					margin-top: 22px;
				`}
			}

			${Subheading} {
				margin-top: auto;
			}
		}

		${NextEpisode} {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 0;
			right: 60px;
			z-index: 2;
		
			${Subheading} {
				${type.heading4};

				&:first-child {
					color: ${colours.lemon}
				}
			}

			${media.phone`
				right: auto;
				left: 0;
				top: 12px;
			`}
		}

		${media.phone`
			padding: 0;

			${Heading},
			${Text},
			${Subheading} {
				${padding}
			}
			
		`}
	}

	${ImageWrapper} {
		position: relative;

		&.desktop {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 1;
			
			video {
				object-fit: cover;
			}
		}

		&.phone {
			width: 100vw;
			margin-top: 20px;
		}
	}	
`

// Insights

const Insights = styled.div`
	${section}
	margin-bottom: 240px;

	${media.tablet`
		margin-bottom: 120px;
	`}

	${media.phone`
		margin-bottom: 110px;
	`}

	${Info} {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-right: auto;
		padding-right: 25px;

		${Heading} {
			display: flex;
			align-items: center;
			overflow: hidden;
			${type.heading1}

			${media.phone`
				margin-bottom: 26px;
			`}
		}

		${Text} {
			margin-top: 50px;
			${type.heading3};
			max-width: 745px;

			p {
				margin-bottom: 28px;
			}

			${media.phone`
				margin-top: 0;
			`}
		}

		${Subheading} {
			margin-top: auto;
		}

		${condensedLogoResponsive}
	}

		
	${ImageWrapper} {
		${BGImage} {
			width: 457px;
			height: 270px;

			${media.phone`
				width: 100%;
				height: 200px;
			`}
		}

		${media.tablet`
			margin-left: 24px;
		`}

		${media.phone`
			margin-left: 0;
			margin-bottom: 22px;
		`}
	}

	
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index